import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { screenMax } from 'styles/helpers/responsive'
import { Button, Wrapper, Grid } from 'components/common'
import { COLOR, FONT, GTR } from 'styles/tokens'

const StyledContent = styled(Wrapper)`
  margin-bottom: ${GTR.XL};

  * {
    font-family: ${FONT.FAMILY.BODY} !important;
  }

  ul {
    list-style: disc;
    padding-left: ${GTR.M};
    margin-bottom: ${GTR.L};

    li {
      &:not(:last-of-type) {
        margin-bottom: ${GTR.XS} !important;
      }
    }
  }

  p {
    line-height: 1.7;
  }

  h1,
  h1 > *,
  h2,
  h2 > *,
  h3,
  h3 > * {
    font-size: ${FONT.SIZE.XL} !important;
    letter-spacing: 0 !important;
    font-weight: ${FONT.WEIGHT.BOLD} !important;
    line-height: 1.25 !important;
    margin-bottom: ${GTR.M} !important;
    margin-top: ${GTR.XL} !important;
  }

  ${screenMax.s`
    h1,
    h1 > *,
    h2,
    h2 > *,
    h3,
    h3 > * {
      font-size: ${FONT.SIZE.L} !important;
      margin-top: ${GTR.L} !important;
    }
  `}

  >*:first-child {
    margin-top: 0 !important;
  }
`

const Description = ({ job }) => {
  return (
    <Wrapper background={COLOR.BACKGROUND.PALE}>
      <Wrapper container>
        <Grid>
          <Grid.Item spanFromL={10} startColumnFromL={2}>
            <StyledContent
              dangerouslySetInnerHTML={{ __html: job.publicDescription }}
            />
            <Wrapper margin={['XL', 0, 0]} centered>
              <Button.Primary tag={Link} wide to={`${job.internalUrl}/apply`}>
                Apply for this job
              </Button.Primary>
            </Wrapper>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </Wrapper>
  )
}

Description.propTypes = {
  // eslint-disable-next-line
  job: PropTypes.object.isRequired,
}

export default Description
