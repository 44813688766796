import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Button, Wrapper, H } from 'components/common'
import { COLOR, FONT, GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

import JobDetails from 'components/JobDetails'
import SaveJob from 'components/SaveJob'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const StyledJobDetails = styled(JobDetails)`
  font-size: ${FONT.SIZE.L};
  margin-bottom: ${GTR.M};

  ${screenMax.s`
    font-size: ${FONT.SIZE.M};
    margin-bottom: 0;
  `}
`

const StyledButtonWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${screenMax.s`
    flex-direction: column;
  `}
`

const StyledTitleLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const Hero = ({ job, showButtons }) => {
  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']}>
          {showButtons ? (
            job.title
          ) : (
            <StyledTitleLink to={job.internalUrl}>{job.title}</StyledTitleLink>
          )}
        </H>
        <Wrapper centered>
          <StyledJobDetails job={job} color={COLOR.WHITE} />
          {showButtons && (
            <StyledButtonWrapper>
              <Wrapper
                margin={0}
                marginFromM={[0, 'XXS', 0, 0]}
                style={{ display: 'flex' }}
              >
                <SaveJob jobId={job.id} />
              </Wrapper>
              <Button.Primary tag={Link} to={`${job.internalUrl}/apply`} wide>
                Apply for this job
              </Button.Primary>
            </StyledButtonWrapper>
          )}
        </Wrapper>
      </Wrapper>
    </StyledWrapper>
  )
}

Hero.propTypes = {
  // eslint-disable-next-line
  job: PropTypes.object.isRequired,
  showButtons: PropTypes.bool,
}

Hero.defaultProps = {
  showButtons: true,
}

export default Hero
