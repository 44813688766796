import React from 'react'
import PropTypes from 'prop-types'
import shuffle from 'lodash/shuffle'

import { COLOR } from 'styles/tokens'

import Layout from 'components/layout'
import SEO from 'components/seo'
import FeaturedJobs from 'components/FeaturedJobs'
import Hero from 'components/JobHero'
import Description from './Description'

const Job = ({ pageContext: { job, groupedJobs } }) => {
  const category = job.categories.data[0]?.name
  const featuredJobs = category
    ? groupedJobs[category].filter(({ id }) => id !== job.id)
    : []

  return (
    <Layout>
      <SEO title={job.title} />
      <Hero job={job} />
      <Description job={job} />
      <FeaturedJobs
        jobs={shuffle(featuredJobs).slice(0, 3)}
        title="Similar jobs"
        background={COLOR.BACKGROUND.PALE}
      />
    </Layout>
  )
}

Job.propTypes = {
  pageContext: PropTypes.shape({
    job: PropTypes.object.isRequired,
    groupedJobs: PropTypes.object,
  }).isRequired,
}

export default Job
