import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { screenMax, screenMin } from 'styles/helpers/responsive'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import CurveWrapper from 'components/CurveWrapper'
import Bullet from 'components/Bullet'
import { alpha } from 'styles/helpers/color'
import { H, Wrapper } from 'components/common'
import { BORDER, COLOR, GTR, FONT } from 'styles/tokens'

const StyledViewButton = styled(Wrapper)`
  text-align: right;
  font-weight: ${FONT.WEIGHT.MEDIUM};

  ${screenMax.s`
    display: none;
  `}
`

const StyledCard = styled(Link)`
  border-radius: ${BORDER.RADIUS.M};
  padding: ${GTR.M} ${GTR.L};
  display: grid;
  grid-template-columns: 3fr 3fr 70px;
  grid-gap: ${GTR.M};
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  height: 100%;

  background: ${alpha(COLOR.WHITE, 0.8)};

  &:hover {
    text-decoration: none;
  }

  ${screenMin.m`
    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      background-color: ${COLOR.WHITE};

      ${StyledViewButton} {
        color: ${COLOR.ACCENT.PRIMARY};
      }
    }
  `}

  ${screenMax.s`
    background-color: ${COLOR.WHITE};
    grid-gap: ${GTR.S};
    grid-template-columns: 1fr;
    padding: ${GTR.M};
  `}
`

const StyledDetails = styled.p`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-bottom: 0;
`

const StyledList = styled(motion.ul)`
  li {
    &:not(:last-of-type) {
      margin-bottom: ${GTR.S};
    }
  }
`

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const getBusinessSector = (job) => {
  return job.businessSectors.data[0]?.name
}

const Featured = ({ jobs, title, background, curve }) => {
  const WrapperEl = curve ? CurveWrapper : Wrapper
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  if (jobs.length === 0) return null
  return (
    <WrapperEl background={background} top={33}>
      <Wrapper container>
        <H size="L" centered marginFromM={[0, 0, 'XL']}>
          {title}
        </H>
        <StyledList
          gap="S"
          variants={container}
          initial="hidden"
          animate={controls}
          ref={ref}
        >
          {jobs.map((job) => {
            const businessSector = getBusinessSector(job)
            return (
              <motion.li key={job.id} variants={item}>
                <StyledCard to={job.internalUrl}>
                  <H size="M" margin={0}>
                    {job.title}
                  </H>
                  <StyledDetails>
                    {job.address.city} <Bullet /> {job.employmentType}{' '}
                    {businessSector && (
                      <>
                        <Bullet /> {businessSector}
                      </>
                    )}
                  </StyledDetails>
                  <StyledViewButton>
                    <p>View job</p>
                  </StyledViewButton>
                </StyledCard>
              </motion.li>
            )
          })}
        </StyledList>
      </Wrapper>
    </WrapperEl>
  )
}

Featured.propTypes = {
  curve: PropTypes.bool,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  title: PropTypes.string,
  background: PropTypes.string,
}

Featured.defaultProps = {
  curve: false,
  jobs: [],
  title: 'Featured jobs',
  background: COLOR.ACCENT.PRIMARY,
}

export default Featured
